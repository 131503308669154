<div class="search-field-container">
    @if(!this.responsiveService.responsive){
    <input type="text" placeholder={{placeholderText}} (input)="onSearch($event)">
    }@else{
    <input type="text" placeholder={{placeholderTextResponsive}} (input)="onSearch($event)">
    }
    <img src="../../../../assets/img/header/search.png">

    <div [ngClass]="{'result-container': searchService.isSearching, 'closed-result-container': !searchService.isSearching}">
        @if (channels.length > 0 || users.length > 0 || messages.length > 0;){

        @if (channels.length > 0) {
        <div>
            <h2>Kanale:</h2>
            <ul>
                <li class="channel-container result" *ngFor="let channel of channels; let i = index" (click)="onChannelClick(channel.id)">
                    <ng-container *ngIf="channel.uids.includes(authService.currentUserSignal()?.uId ?? '') || channel.id === 'wXzgNEb34DReQq3fEsAo7VTcXXNA'; else checkUidCondition">
                      <img class="raute" src="../../../../assets/img/sidebar/tag.png" alt="">
                    </ng-container>
                    <ng-template #checkUidCondition>
                      <ng-container *ngIf="!channel.uids.includes(authService.currentUserSignal()?.uId ?? '')">
                        <svg fill="#000000" width="20px" height="20px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M25 12h-1v-3.816c0-4.589-3.32-8.184-8.037-8.184-4.736 0-7.963 3.671-7.963 8.184v3.816h-1c-2.206 0-4 1.794-4 4v12c0 2.206 1.794 4 4 4h18c2.206 0 4-1.794 4-4v-12c0-2.206-1.794-4-4-4zM10 8.184c0-3.409 2.33-6.184 5.963-6.184 3.596 0 6.037 2.716 6.037 6.184v3.816h-12v-3.816zM27 28c0 1.102-0.898 2-2 2h-18c-1.103 0-2-0.898-2-2v-12c0-1.102 0.897-2 2-2h18c1.102 0 2 0.898 2 2v12zM16 18c-1.104 0-2 0.895-2 2 0 0.738 0.405 1.376 1 1.723v3.277c0 0.552 0.448 1 1 1s1-0.448 1-1v-3.277c0.595-0.346 1-0.985 1-1.723 0-1.105-0.895-2-2-2z">
                          </path>
                        </svg>
                      </ng-container>
                    </ng-template>
                    <span [innerHTML]="highlight(channel.name)"></span>
                  </li>
                  
            </ul>
        </div>
        }


        @if (users.length > 0) {
        <h2>Benutzer:</h2>
        <ul>
            <li *ngFor="let user of users" (click)="onUserClick(user.uid)">
                <div class="result-userr">
                    <img class="search-result-avatar" src="{{user.image}}" alt="">
                    <span [innerHTML]="highlight(user.name)"></span>
                </div>
            </li>
        </ul>
        }

        @if (messages.length > 0) {
        <h2>Nachrichten:</h2>
        <ul>
            <li *ngFor="let message of messages" (click)="onMessageClick(message.channelId, message.id)">
                <div class="result-user-container">

                    <div style="display: flex; align-items: center; gap: 5px;">
                        <img [src]="message.userImage" alt="{{message.username}}" class="search-result-avatar">
                        <span><b>{{ message.username }}:</b></span>
                    </div>

                    <div class="result">
                        <span [innerHTML]="highlight(message.message)"></span>
                    </div>
                </div>
            </li>
        </ul>
        }

        } @else {
        <h2>Es gibt keine Übereinstimmung.</h2>
        }

    </div>

</div>

